<template>
  <senso-layout>
    <div class="senso-announcements">
      <!-- All Announcements -->
      <div class="card">
        <div class="header">
          <h3>All Announcements</h3>
        </div>
        <div class="body bb" v-if="!isLoading">
          <!-- Search -->
          <div class="search">
            <a-input-search
              style="max-width: 300px"
              placeholder="Search announcements"
              v-model="search_query"
            />
          </div>

          <!-- / Search -->
        </div>
        <div class="body">
          <!-- Loading -->
          <div class="loader" v-if="isLoadingCombined">
            <a-spin></a-spin>
          </div>
          <!-- / Loading -->

          <!-- No announcements -->
          <a-alert
            v-if="!isLoadingCombined && announcementsToShowOrdered.length == 0"
            type="info"
            message="No announcements to show"
          />
          <!-- / No announcements -->

          <!-- List -->
          <div class="list" v-if="!isLoadingCombined">
            <announcement-list-item
              v-for="(announcement, i) in announcementsToShowOrdered"
              :key="i"
              :announcement="announcement"
            ></announcement-list-item>
          </div>
          <!-- / List -->

          <a-button
            v-if="canCreateAnnouncements && !isLoadingCombined"
            class="create-button"
            icon="plus"
            type="primary"
            @click.prevent="openCreateAnnouncementModal"
            >New Announcement</a-button
          >
        </div>
      </div>
      <!-- / All Announcements -->

      <create-announcement-modal
        :visible="createAnnouncementModalVisible"
        @close-modal="closeCreateAnnouncementModal"
      ></create-announcement-modal>
    </div>
  </senso-layout>
</template>

<script>
import SensoLayout from "./SensoLayout.vue";

import { mapGetters, mapActions } from "vuex";
import Announcements from "../../mixins/Announcements";
import Dates from "../../mixins/Dates";
import organisations from "../../helpers/organisations";
const _ = require("lodash");
import CreateAnnouncementModal from "../../components/Senso/Announcements/CreateAnnouncementModal.vue";
import AnnouncementListItem from "../../components/Senso/Announcements/AnnouncementListItem.vue";

export default {
  components: { SensoLayout, CreateAnnouncementModal, AnnouncementListItem },
  mixins: [Announcements, Dates],
  data() {
    return {
      createAnnouncementModalVisible: false,
    };
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      isLoadingMissionControl: "isLoading",
      canCreateAnnouncements: "canCreateAnnouncements",
    }),

    ...mapGetters("announcements", {
      announcementsToShowOrdered: "announcementsToShowOrdered",
      isLoading: "isLoading",
      searchQuery: "searchQuery",
    }),

    search_query: {
      set(val) {
        this.setSearchQuery(val);
      },
      get() {
        return this.searchQuery;
      },
    },

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    },
  },
  methods: {
    ...mapActions("missionControl", {
      getOrganisationById: "getOrganisationById",
    }),

    ...mapActions("announcements", {
      loadAnnouncements: "loadAnnouncements",
      setSearchQuery: "setSearchQuery",
    }),

    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },

    getAnnouncementUrl(announcement) {
      let organisation = this.getOrganisationById(announcement.ownerId);
      let tenantId = organisations.getOrganisationTenantId(organisation);
      return "/senso/announcements/" + tenantId + "/" + announcement.id;
    },

    openCreateAnnouncementModal() {
      this.createAnnouncementModalVisible = true;
    },

    closeCreateAnnouncementModal() {
      this.createAnnouncementModalVisible = false;
    },
  },
};
</script>

<style scoped>
.create-button {
  margin-top: 20px;
}
</style>